<template>
    <div class="match-report-detail">
        <iframe id="iframe" :src="reportUrl" width="100%" height="100%" frameborder="0"></iframe>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, getCurrentInstance, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default defineComponent({
    name: 'match-report-detail',
    setup() {
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const state = reactive({
            reportUrl: '',

            rlen: null,
        });

        onMounted(() => {
            state.reportUrl = route.query.url;

            state.rlen = history.length;

            window.addEventListener('message', listenerFn);
        });

        const listenerFn = (event) => {
            if (event.data.data.code === 'close') {
                // rlen 进入iframe嵌套页面之前历史记录长度
                // console.log(history.length)
                let len = state.rlen - history.length - 1;//-1是不进入iframe页面的下级页面直接退出的话，执行后退一步的操作
                router.go(len)
            }
        }

        onUnmounted(() => {
            window.removeEventListener('message', listenerFn);
            console.log('remove')
        })

        return {
            ...toRefs(state),
        };
    },
});
</script>

<style lang="scss" scoped>
.match-report-detail {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: relative;
    z-index: 99;
}
</style>
